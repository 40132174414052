<template>
  <v-card elevation="0" class="transparent px-2 py-2">
    <div class="fs-24 color-state font-weight-bold mb-2">
      <span>Sertifikat TOEFL Equivalent Test</span>
    </div>
    <v-card-text class="px-0">
      <v-card class="rounded-lg" elevation="0">
          <!-- :items-per-page.sync="alls.limit"
          :page.sync="alls.page" -->
        <div>
          <v-data-table
            :loading="process.run"
            loading-text="Menyiapkan data Sertifikat"
            :headers="headers"
            :items="alls.list"
            item-key="order_id"
            :items-per-page.sync="alls.limit"
            :page.sync="alls.page"
            :server-items-length="alls.total"
            class="color-navy pl-5 pr-5 text-center"
            @update:sort-desc="sortDesc"
          >
            <template class="text-center" slot="item.end_test" slot-scope="{ item }">
              {{ currentDateTime(item.end_test) }}
            </template>
            <template slot="item.order_no" slot-scope="{ item }">
              {{ item.order_no.replace('YEC', 'DE') }}
            </template>
            <!-- <template class="text-center" slot="item.shipping.status" slot-scope="{ item }">
              <span class="text-capitalize">
                {{ item.shipping.status ? item.shipping.status : '-' }}
              </span>
            </template> -->
            <template slot="item.actions" slot-scope="{ item }">
              <v-btn
                class="pa-0 text-capitalize"
                text
                color="primary"
                :href="`https://certificate.toefldiscoveryenglish.com/pdf/?id=${item.license}`"
                target="_blank">
                Lihat
              </v-btn>
            </template>
            <template class="text-center" slot="item.shipping.order_id" slot-scope="{ item }">
              <v-btn
                class="pa-0 text-capitalize"
                text
                :disabled="item.shipping.waybill_id == ''"
                @click="trackingDetail(item)"
                >{{ item.shipping.waybill_id != '' ? item.shipping.waybill_id : '-' }}</v-btn
              >
            </template>
          </v-data-table>
        </div>
      </v-card>
    </v-card-text>
    <!--     <v-dialog
      v-model="dialogCertif"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar
          dark
          color="primary"
          style="z-index: 10000"
        >
          <v-btn
              dark
              text
              color="primary"
              @click="generateReport()"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            dark
            color="primary"
            @click="dialogCertif = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
             @hasGenerated="hasGenerated($event)" -->
    <!-- <v-card> -->
    <!-- </body> -->
    <!-- </v-card> -->
    <!-- </v-dialog> -->
  </v-card>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "certif-list",
  data() {
    return {
      output: null,
      tampil: false,
      certif: [],
      alls: {
        list: [],
        limit: 10,
        page: 1
      },
      details: [],
      members: [],
      listening: [],
      reading: [],
      structure: [],
      certificate: [],
      id: "",
      id_certif: "",
      codeQR: "",
      headers: [
        {
          text: "Order ID",
          align: "left",
          sortable: false,
          value: "order_no",
        },
        {
          text: "Tanggal Test Selesai",
          align: "center",
          sortable: true,
          value: "end_test",
        },
        { text: "Skor Tes TOEFL Equivalent Test", align: "center", sortable: false, value: "score" },
        {
          text: "Sertifikat",
          align: "center",
          sortable: false,
          value: "actions",
        },
        // {
        //   text: "Nomor Resi",
        //   align: "center",
        //   sortable: false,
        //   value: "shipping.order_id",
        // },
        // {
        //   text: "Status",
        //   align: "center",
        //   sortable: false,
        //   value: "shipping.status",
        // },
      ],
      dialogCertif: false,
      showCertif: false,
      sort: "updated_at",
      dir: "asc",
      submiting: false,
      process: {
        run: false
      }
    };
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getData() {
      this.process.run = true;
      await this.axios
        .get(`/users/v1/member/order/list-sertificate`,
        {
          params: {
            limit: this.alls.limit,
            page: this.alls.page,
            sort_by: 'end_test',
            sort_type: 'desc'
          },
        })
        .then((response) => {
          let res = response.data.data;
          if (response.status == 200) {
            this.loading = false;
            this.process.run = false;
            this.alls = res;
            // console.log(this.alls);
          }
        }).catch((error) => {
          console.log(error.response);
          this.process.run = false
        });
    },
    trackingDetail(item) {
      this.$router.push('/certificate/' + item.shipping.order_id + '/tracking' );
    },
    currentDateTime(a) {
      return moment(a).format("D MMMM YYYY HH:MM");
    },
    dateBirthTest(a) {
      return moment(a).format("MMMM D, YYYY");
    },
    dateExpired(a) {
      let currentDate = moment(a);
      let futureYear = moment(currentDate).add(1, "Y");
      return moment(futureYear).format("MMMM D, YYYY");
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output("blob")
        .then((blob) => {
          let formData = new FormData();
          let certif = blob;

          formData.append("file", certif, "Sertifikat.pdf");
          formData.append("source", "upload");
          formData.append("title", "Sertifikat");

          this.$store
            .dispatch("media/create", formData)
            .then((res) => {
              if (res.status == 200) {
                this.id_certif = res.data.id;
                this.updateCertif(this.id_certif);
                console.log("result", this.id_certif);
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        });
    },
    updateCertif(id) {
      let order_id = this.details.id;
      let sertificate_id = id;

      this.axios
        .put(
          `/users/v1/member/order/update-sertificate`,
          { order_id, sertificate_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            // this.members = res;
            this.getDetailCertif(sertificate_id);
            console.log("berhasil", res);
          }
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    getDetail(order) {
      this.$set(order, "submiting", true);
      this.id = order.id;
      this.axios
        .get(`/users/v1/member/order/detail-full/${this.id}`)
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          // this.$set(order, 'submiting', false);
          console.log(res);
          if (response.status == 200) {
            this.loading = false;
            this.details = res;
            this.reading = res.section.reading;
            this.listening = res.section.listening;
            this.structure = res.section.structure;
            this.certificate = res.sertificate;
            this.codeQR =
              "https://certificate.toefldiscoveryenglish.com/review/?id=" + res.license;
            // this.getDetailMember(res.member_id);
            this.generateReport();
            this.getData();
          }
        });
    },
    getDetailMember(member_id) {
      // console.log('id member',member_id);

      let params = {
        id: member_id,
      };
      this.axios.get(`/users/v1/member/detail`, params).then((response) => {
        let res = response.data.data;
        this.loading = false;
        if (response.status == 200) {
          this.loading = false;
          this.members = res;
          this.generateReport();
        }
      });
    },
    getDetailCertif(id) {
      this.id = id;
      this.axios
        .get(`/storage/v1/media/user/detail/${this.id}`)
        .then((response) => {
          let res = response.data.data;
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            let certificate = res.public_url;
            window.open(certificate);
            // console.log('detail sertificate', res);
          }
        });
    },
    view() {
      this.showCertif = true;
    },
    goTest() {
      this.$router.push("/epot-preparation");
      window.location.reload(true);
    },
    sortDesc($event) {
      this.dir = $event[0] ? "desc" : "asc";
      this.$emit("refresh-list", this.sort, this.dir);
      console.log("dir", $event);
    },
  },
  watch: {
    "alls.limit": async function () {
      this.$emit("refresh-list", this.sort, this.dir);
      await this.getData()
    },
    "alls.page": async function () {
      this.$emit("refresh-list", this.sort, this.dir);
      await this.getData()
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Condensed:700");
tr:nth-child(even) {
  background-color: #f9fafc;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: 0px solid transparent !important;
}
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: 0px solid transparent !important;
  font-size: 14px;
  font-weight: 600;
  color: #8492a6;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  color: #3c4858;
}
.theme--light.v-data-table .v-data-footer {
  border-top: 0px solid transparent !important;
  padding: 16px !important;
}

.vue-html2pdf .layout-container[data-v-1fd3ad26] {
  background: #fff !important;
}
.vue-html2pdf tr:nth-child(even) {
  background-color: #fff !important;
}
@media print {
  @page {
    size: landscape;
  }
}
</style>
<template>
		<body style="width: 100%;">
			<v-row>
				<section class="head text-center" style="width: 100%; font-family: arial;margin-bottom: 15px;">
					<br/>
					<table style="width:70%;margin: auto;">
						<tr>
							<td style="width:5%; text-align: center;">
							<img src="@/assets/logo/logo_unmer.png">	
							</td>
							<td style=" width:86%;text-align: center;">
							<p style="font-size: 15px;font-weight: 400;">LEMBAGA KURSUS DAN PELATIHAN</p>
							<p style="font-size: 18px;font-weight: 700;">YUREKA EDUCATION CENTER</p>
							<span style="font-size: 10px;font-weight: 400;">Izin Operasional Dinas Pendidikan Nomor 303c/KPTS/2019 </span><br/>
							<span style="font-size: 10px;font-weight: 400;">Nomor Pokok Sekolah Nasional (NPSN) Nomor K9990451 </span>	
							</td>
							<td style="width:15%;">
								
							</td>
						</tr>
						<tr>
							<td style="width:5%; text-align: center;">
							</td>
							<td style=" width:86%;text-align: center;padding-top: 15px;">
								<p style="font-size: 15px;font-weight: 700; ">CERTIFICATE OF ACHIEVEMENT</p>

								<p style="font-size: 10px;font-weight: 400;">ID Certificate : #93213494</p>
								<p></p>
								<div style="font-size: 10px;font-weight: 400;margin-bottom: 25px;">
									<p>This is to certify that</p>

									<p style="line-height: 0.25rem;">Name : Andrianus Tabuna Mali</p>
									<p style="line-height: 0.25rem;">Date of Birth : April 05, 1994</p>
									<p style="line-height: 0.25rem;">Test Date : November 24, 2020</p>
								</div>
								<p style="font-size: 10px;font-weight: 400;margin-bottom: 15px;">has taken <b>TOEFL Equivalent Test</b> and achieved the following scores</p>
								<table style="width:58%;font-size: 10px;font-weight: 400;margin-bottom: 10px;text-align: right;">
										<tr>
											<td style="padding: 1px;">Reading </td>
											<td style="padding: 1px;">:</td>
											<td style="padding: 1px;text-align: left;">27</td>
										</tr>
										<tr>
											<td style="padding: 1px;">Structure </td>
											<td style="padding: 1px;">:</td>
											<td style="padding: 1px;text-align: left;">15</td>
										</tr>
										<tr>
											<td style="padding: 1px;">Listening </td>
											<td style="padding: 1px;">:</td>
											<td style="padding: 1px;text-align: left;">15</td>
										</tr>
										<tr>
											<td style="padding: 1px;"><b>Total Correct Point</b></td>
											<td style="padding: 1px;">:</td>
											<td style="padding: 1px;text-align: left;">49</td>
										</tr>
										<tr>
											<td style="padding: 1px;"><b>Your Prediction Score for TOEFL</b></td>
											<td style="padding: 1px;">:</td>
											<td style="padding: 1px;text-align: left;">390</td>
										</tr>
								</table>
								<p style="font-size: 10px;font-weight: 700;text-align: center;">Proficiency Level  :  Upper intermediate</p>
							</td>
							<td style="width:15%;">
								
							</td>
						</tr>
					</table>
				</section>
				<br/>
				<section class="foot" style="width: 100%; font-family: arial;">
					<table style="width:70%;margin:auto;">
						<tr>
							<td style="width:15% ; text-align: center;">
								<p style="font-size: 6px;font-weight: 400;">ID Certificate: #93213494</p>
								<img src="@/assets/images/barcode.png"/>
								<p style="font-size: 6px;font-weight: 400;">scan me to verify this certificate</p>
							</td>
							<td style="width:70% ;">
								<p style="font-size: 10px;font-weight: 400;">This certificate is acceptable until <b>November 24, 2021.</b></p>
								<p style="font-size: 10px;font-weight: 400;line-height: 0.25rem;">TOEFL is a registered trademark of Educational Testing Service</p>
								<p style="font-size: 10px;font-weight: 400;line-height: 0.25rem;">(ETS), This document is not endorsed or approved by ETS</p>
							</td>
							<td style="width:30% ;">
								<img src="@/assets/images/ttd-certif.png"/>
							</td>
						</tr>
					</table>
				</section>
				<section class="next" style="width: 100%; font-family: arial;page-break-before:always;">
					<div style="width:70%;margin:auto;">
						<p style="font-size: 18px;font-weight: 700;">Certificate Verification</p>
						<div style="width: 50%;margin-bottom: 10px;">							
							<p style="font-size: 10px;font-weight: 400;color: rgba(0, 0, 0, 0.5);">Each certificate has unique QR code. Please scan the QR Code by using QR Code scanner application and click the link appeared after scanning. The link will lead you to a website page that presents the exact information as written on the certificate.If the information on this certificate is not the same as on the website page; especially the name and the score; this certificate is considered as counterfeit or not valid.</p>
							<p style="font-size: 18px;font-weight: 700;">Proficiency Level</p>
							<table style="border-collapse: collapse;width: 100%">
								<tr>
									<th style="border:0.5px solid #00000080;text-align: left;padding: 8px;">Score</th>
									<th style="border:0.5px solid #00000080;text-align: left;padding: 8px;">Proficiency Level</th>
								</tr>
								<tr>
									<td style="border:0.5px solid #00000080;padding: 8px;">310 - 400</td>
									<td style="border:0.5px solid #00000080;padding: 8px;">Beginner</td>
								</tr>
								<tr>
									<td style="border:0.5px solid #00000080;padding: 8px;">403 - 460</td>
									<td style="border:0.5px solid #00000080;padding: 8px;">Intermediate</td>
								</tr>
								<tr>
									<td style="border:0.5px solid #00000080;padding: 8px;">463 - 520</td>
									<td style="border:0.5px solid #00000080;padding: 8px;">Upper intermediate</td>
								</tr>
								<tr>
									<td style="border:0.5px solid #00000080;padding: 8px;">523 - 677</td>
									<td style="border:0.5px solid #00000080;padding: 8px;">Advanced</td>
								</tr>
							</table>
						</div>
						<p style="font-size: 10px;font-weight: 400;margin-bottom: 0px;">Issued by Yureka Education Center</p>
						<p style="font-size: 10px;font-weight: 400;">amikom.ac.id</p>						
					</div>
				</section>
			</v-row>
		</body>
	</template>
<script>
	export default {
		name:'viewer',
	}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Saira+Condensed:700');
</style>
<!-- <template>
<div>
  <pdf src="./assets/example.pdf"></pdf>
</div>
</template>
 
<script>
import pdf from 'vue-pdf'
 
export default {
	name:'viewer',
  components: {
    pdf
  }
} -->
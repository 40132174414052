var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"transparent px-2 py-2",attrs:{"elevation":"0"}},[_c('div',{staticClass:"fs-24 color-state font-weight-bold mb-2"},[_c('span',[_vm._v("Sertifikat TOEFL Equivalent Test")])]),_c('v-card-text',{staticClass:"px-0"},[_c('v-card',{staticClass:"rounded-lg",attrs:{"elevation":"0"}},[_c('div',[_c('v-data-table',{staticClass:"color-navy pl-5 pr-5 text-center",attrs:{"loading":_vm.process.run,"loading-text":"Menyiapkan data Sertifikat","headers":_vm.headers,"items":_vm.alls.list,"item-key":"order_id","items-per-page":_vm.alls.limit,"page":_vm.alls.page,"server-items-length":_vm.alls.total},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.alls, "limit", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.alls, "limit", $event)},"update:page":function($event){return _vm.$set(_vm.alls, "page", $event)},"update:sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"item.end_test",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currentDateTime(item.end_test))+" ")]}},{key:"item.order_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.order_no.replace('YEC', 'DE'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0 text-capitalize",attrs:{"text":"","color":"primary","href":("https://certificate.toefldiscoveryenglish.com/pdf/?id=" + (item.license)),"target":"_blank"}},[_vm._v(" Lihat ")])]}},{key:"item.shipping.order_id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0 text-capitalize",attrs:{"text":"","disabled":item.shipping.waybill_id == ''},on:{"click":function($event){return _vm.trackingDetail(item)}}},[_vm._v(_vm._s(item.shipping.waybill_id != '' ? item.shipping.waybill_id : '-'))])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
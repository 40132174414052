<template>
  <div ref="screen">
    <v-snackbar
      :timeout="snackbar.timeout"
      v-model="snackbar.state"
      bottom
      right
      style="padding: 0 18% 1% 0 !important"
      :color="snackbar.color"
      text
    >
      <v-icon left color="#8C7913">{{ snackbar.icon }}</v-icon>
      <span style="color: #8C7913">{{ snackbar.text }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="#8C7913" text v-bind="attrs" @click="snackbar.state = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <div class="text-center mt-10 mb-8">
      <speech-to-text :text.sync="text"></speech-to-text>
    </div> -->
    <v-row>
      <v-col cols="12" class="pa-0" style="z-index: 9999">
        <!-- <div class=""> -->
        <video
          v-if="showDetector"
          ref="video"
          class="test-video"
          id="video"
          width="240"
          height="140"
          @loadedmetadata="runModel"
          autoplay
        ></video>
        <video v-else ref="video" class="test-video" id="video" width="240" height="140" autoplay></video>
        <canvas
          width="240"
          height="140"
          ref="canvas"
          style="z-index: 9999;position: fixed;right: 1%;bottom: 3%;width: 240px;height: 140px;"
        />
        <!-- </div> -->
      </v-col>
      <template v-if="loading.state">
        <v-col cols="12" md="8" class="ma-auto">
          <v-spacer class="py-16"></v-spacer>
          <div v-if="attempt.alert" class="text-center mb-8">
            <v-alert close-text="Close Alert" class="d-inline-block" color="primary" text>
              Perangkat gagal mengakses audio Section Listening
              <v-btn icon color="primary" right @click="$set(attempt, 'alert', false)">
                <v-icon>
                  $close
                </v-icon>
              </v-btn>
            </v-alert>
          </div>

          <p class="fs-24 primary--text font-weight-bold text-center">
            Preparing Listening Section
          </p>
          <v-spacer class="py-5"></v-spacer>
          <v-progress-linear color="primary accent-4" indeterminate rounded height="6"></v-progress-linear>
          <div class="text-center color--text text--lighten-3">
            {{ loading.text }}
          </div>

          <div v-if="attempt.error" class="text-center mt-12">
            <v-btn v-if="attempt.times < 3" color="primary" @click="reset" large elevation="0">
              Ulangi Akses Audio Listening
            </v-btn>
            <v-row v-else justify="center">
              <v-col class="text-left" cols="8">
                <v-alert color="#FEFBD8">
                  <div class="font-weight-bold" style="color: #8C7913;">
                    Perangkat anda gagal Mengakses Audio Listening Sebanyak 3x
                  </div>
                  <div>
                    <ul style="color: #8C7913;">
                      <li>
                        Mohon beralih ke Incognito Google Chrome dengan menggunakan shortcut Ctrl + Shift + N atau
                        setting manual
                        <ol>
                          <li>
                            Dikanan atas, klik menu pada Google Chrome
                            <v-icon color="#8C7913" right>ri-more-2-fill</v-icon>
                          </li>
                          <li>Pilih New Incognito window</li>
                          <li>Login kembali ke akun TOEFL Equivalent Test</li>
                        </ol>
                      </li>
                      <li>Pastian Jaringan Internet anda stabil saat sistem sedang mengakses Audio Listenin</li>
                      <li>
                        Jika masih belum berhasil, mohon menganti perangkat yang lain, agar tidak menganggu saat test
                        berlangsung
                      </li>
                    </ul>
                  </div>
                </v-alert>
                <div class="text-center">
                  <v-btn class="mr-2" color="primary" @click="backHome" outlined large elevation="0">
                    Kembali ke Halaman Utama
                  </v-btn>
                  <v-btn class="ml-2" color="primary" @click="reset" large elevation="0">
                    Ulangi Akses Audio Listening
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12" md="3" class="pa-1" style="position: fixed">
          <!-- :style="{position: $vuetify.breakpoint.mdAndUp ? 'fixed' : 'initial'}" -->
          <v-card
            rounded="lg"
            elevation="0"
            class="ma-auto px-4 py-2 overflow-auto mx-width"
            style="max-height: calc(100vh - 70px)"
          >
            <!-- # Display Timer -->
            <div class="box-timer pa-2 py-0">
              <p class="font-weight-medium color-state fs-18 mb-0">
                Waktu tersisa
              </p>
              <p class="primary--text font-weight-bold fs-20 mb-0">
                {{ display_timer }}
                <v-progress-circular
                  v-if="buffering"
                  width="2"
                  size="20"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </p>
            </div>
            <v-divider></v-divider>

            <div class="pa-2 mb-3" v-show="showIconTab || showIconFace || showIconSpeech">
              <div class="font-weight-medium color-state fs-18">
                Pelanggaran
              </div>
              <v-row align="center" class="mt-2">
                <v-col v-if="showIconTab" cols="4" md="4" sm="4" class="text-center pa-0">
                  <div class="pl-0 mb-2" style="font-size: .6rem !important">
                    Tab Baru
                  </div>
                  <v-badge :content="lms_open_tab()" bottom color="#EF2F39" overlap>
                    <v-avatar tile size="25">
                      <v-img :src="require('@/assets/images/open_new_tab.png')"></v-img>
                    </v-avatar>
                  </v-badge>
                </v-col>
                <v-col v-if="showIconFace" cols="4" md="4" sm="4" class="text-center pa-0">
                  <div class="pl-0 mb-2" style="font-size: .6rem !important">
                    Deteksi Wajah
                  </div>
                  <v-badge :content="lms_face_detection()" bottom color="#EF2F39" overlap>
                    <v-avatar tile size="25">
                      <v-img :src="require('@/assets/images/face_detection.png')"></v-img>
                    </v-avatar>
                  </v-badge>
                </v-col>
                <v-col v-if="showIconSpeech" cols="4" md="4" sm="4" class="text-center pa-0">
                  <div class="pl-0 mb-2" style="font-size: .6rem !important">
                    Speech Voice
                  </div>
                  <v-badge :content="lms_speech_detection()" bottom color="#EF2F39" overlap>
                    <v-avatar tile size="25">
                      <v-img :src="require('@/assets/images/speech_detection.png')"></v-img>
                    </v-avatar>
                  </v-badge>
                </v-col>
              </v-row>
            </div>
            <v-divider></v-divider>

            <!-- # Display Navigation question -->
            <div class="navigasi pa-2" style="max-width: 305px">
              <div class="font-weight-medium color-state fs-18">
                Navigasi soal
              </div>
              <div id="tes-structure" class="btn-question">
                <v-row>
                  <v-col class="py-0">
                    <v-btn
                      v-for="index in questions.length"
                      :key="index"
                      class="mx-2 my-2 fs-12 py-3"
                      x-small
                      :class="button_status(index)"
                      style="width: 35px"
                      elevation="0"
                    >
                      {{ index }}
                    </v-btn>
                  </v-col>
                  <!-- <v-btn
                    x-small
                    outlined
                    class="mx-2 my-2 fs-12 py-3 text-justify"
                    style="width: 35px"
                    elevation="0"
                    v-for="{index} in list_question"
                    :key="index"
                  >
                    {{ index + 1 }}
                  </v-btn> -->
                  <!-- <v-btn x-small outlined class="mx-2 my-2 fs-12 py-3 text-justify" style="width: 35px;"  elevation="0" v-for="n in questionS.length" :key="n">
                    {{n}}
                  </v-btn> -->
                </v-row>
              </div>
            </div>
            <v-divider></v-divider>

            <!--  # Display soal terjawab dan belum -->
            <div class="cek-question pa-2 px-0">
              <table>
                <tr style="color: #61ac33">
                  <td>Soal Terjawab</td>
                  <td></td>
                  <td>:</td>
                  <td class="ms-2 font-weight-medium">
                    {{ answered_questions.length }}
                  </td>
                </tr>
                <tr style="color: #cd223a">
                  <td>Belum Terjawab</td>
                  <td></td>
                  <td>:</td>
                  <td class="ms-2 font-weight-medium">
                    {{ questions.length - answered_questions.length }}
                  </td>
                </tr>
              </table>
            </div>
            <!--  <v-divider></v-divider>
            <div class="box-volume pa-2 py-3">
              <p class="font-weight-medium color-state fs-18 mb-2">Volume</p>
              <v-slider
                v-model="volume"
                prepend-icon="mdi-volume-high color-state"
                min="0"
                max="100"
                inverse-label
              >
                <template v-slot:append>
                  <v-text-field
                    v-model="volume"
                    class="mt-0 pt-0 border-0 color-disable"
                    hide-details
                    readonly
                    type="number"
                    style="width: 60px"
                  ></v-text-field>
                </template>
              </v-slider>
            </div> -->
          </v-card>
        </v-col>

        <!-- <v-col cols="12" md="9" offset-md="3"> -->
        <!-- # Display Content Test -->
        <v-col cols="12" md="9" class="pl-2 pa-1" offset-md="3">
          <v-card rounded="lg" elevation="0" style="min-height: 110vh">
            <v-card-text>
              <div class="title">
                <v-row>
                  <v-col cols="12" md="8" style="align-self: center">
                    <div class="fs-24 font-weight-bold color-state">
                      Listening Section
                    </div>
                  </v-col>
                  <v-col>
                    <!-- <div class="fs-24 font-weight-bold color-state">
                        <v-btn @click="go_to_next_section()" outlined color="primary">
                          Skip Listening
                        </v-btn>
                    </div> -->
                  </v-col>
                </v-row>
              </div>
              <div class="box-section">
                <div class="greeting" v-if="show_direction">
                  <!-- :src="current_part && current_part.part_direction.media.type == 'audio'? current_part.part_direction.public_ : ''" -->

                  <div class="d-none">
                    <audio-player
                      v-if="
                        current_part.part_direction &&
                          current_part.part_direction.with_media &&
                          current_part.part_direction.media.type == 'audio'
                      "
                      class="px-0"
                      elevation="0"
                      autoplay
                      :controller="false"
                      :src="current_part.part_direction.media.public_url"
                      @waiting="
                        buffering = true;
                        timer_stop();
                      "
                      @playing="
                        buffering = false;
                        timer_start();
                      "
                    ></audio-player>
                  </div>
                  <div class="instruction pb-10">
                    <div class="fs-18 color-state mb-2">
                      {{ `Part ${current_part.part || ""}` }}
                    </div>
                    <div class="fs-14 color-disable text-justify" style="white-space: pre-line">
                      {{ current_part.part_direction ? current_part.part_direction.text : "" }}
                    </div>
                  </div>
                  <div v-if="beginning_of_section" class="alert-instruction text-center">
                    <v-alert text warning>
                      <span class="fs-14 color-warning-cutsom"
                        >Pastikan Anda mendengar audio yang terputar di halaman ini. Atur besar kecil audio dengan
                        kontrol volume di perangkat Anda. Gunakan headset/earphone untuk pendengaran yang lebih baik.
                      </span>
                    </v-alert>
                  </div>
                </div>
                <div class="question" v-else-if="current_list">
                  <div class="alert-instruction">
                    <v-alert text>
                      <span class="fs-14 color-warning-cutsom"
                        >Perhatian: Pastikan menjawab soal listening sebelum waktu habis karena anda tidak dapat kembali
                        ke soal sebelumnya</span
                      >
                    </v-alert>
                  </div>
                  <audio-player
                    v-if="current_list.is_group && current_list.with_media && current_list.media.type == 'audio'"
                    class="px-0"
                    elevation="0"
                    autoplay
                    :controller="false"
                    :src="current_list.media.public_url"
                    @waiting="
                      buffering = true;
                      timer_stop();
                    "
                    @playing="
                      buffering = false;
                      timer_start();
                    "
                  ></audio-player>
                  <epot-question
                    v-for="(question, index) in current_list.list_question"
                    :key="question.id"
                    :data="question"
                    :number="current + index"
                    @update:data="$set(current_list.list_question, index, $event)"
                  />
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-dialog v-model="show_close_statement" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card class="ma-5">
            <v-card-text class="col-md-8 py-10 ma-auto text-center">
              <div class="d-none">
                <audio-player
                  v-if="
                    show_close_statement &&
                      current_part.close_statement &&
                      current_part.close_statement.with_media &&
                      current_part.close_statement.media.type == 'audio'
                  "
                  class="px-0"
                  elevation="0"
                  autoplay
                  :controller="false"
                  :src="current_part.close_statement.media.public_url"
                  @waiting="
                    buffering = true;
                    timer_stop();
                  "
                  @playing="
                    buffering = false;
                    timer_start();
                  "
                ></audio-player>
              </div>
              <p class="fs-20 font-weight-bold color-disable pb-5">
                <span v-if="end_of_section">End of listening section</span>
                <span v-else>End of {{ `Part ${current_part ? current_part.part : ""}` }}</span>
              </p>
              <p class="fs-20 font-weight-bold color-disable pb-5">
                {{ current_part.close_statement ? current_part.close_statement.text : "" }}
              </p>
              <p class="fs-24 font-weight-bold color-state pb-5" style="line-height: 2rem">
                Selanjutnya Anda akan mengerjakan
                {{
                  end_of_section
                    ? "Section 2 (Structure & Written Expression Section)"
                    : "Part " + (current_part.part == "A" ? "B" : "C")
                }}
              </p>
              <template v-if="end_of_section">
                <div class="info-question m-auto text-center pb-10">
                  <div class="">
                    <span
                      class="
                        primary--text
                        font-weight-medium
                        fs-14
                        text-center
                        me-3
                      "
                      ><v-icon>ri-timer-line</v-icon> 25 menit</span
                    >
                    <span class="primary--text font-weight-medium fs-14 text-center"
                      ><v-icon>ri-file-text-line</v-icon> 40 soal</span
                    >
                  </div>
                </div>
                <v-btn color="primary" large elevation="0" @click="go_to_next_section" class="font-weight-bold">
                  Lanjutkan ke sesi berikutnya
                </v-btn>
              </template>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogSubmit.state" width="500">
          <v-card>
            <v-card-title class="font-weight-medium fs-24 color-state">
              Peringatan
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="fs-14 py-4">
              <span class="color-disable">{{ dialogSubmit.message }}</span>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="fs-18 pa-4 py-6"
                @click="dialogSubmit.status == 'gugur' ? gugur_test() : go_to_next_section()"
                :loading="submiting"
              >
                Kirim ulang
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>

      <!-- # DIALOG OPEN TAB -->
      <v-dialog v-model="dialog.identification_tab" persistent width="600">
        <v-card flat v-if="user.is_b2b == 0">
          <v-card-title style="color: #3C4858" class="font-weight-bold">
            Anda Telah Membuka Tab Baru atau meninggalkan halaman tes
          </v-card-title>
          <v-card-text>
            Anda telah membuka tab baru atau meninggalkan halaman tes , mohon untuk tidak membuka tab atau meninggalkan
            halaman tes lain agar tidak mengurangi keakurasian hasil tes.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large @click="plus_counter_tab('open_new_tab')" color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card flat v-else>
          <v-card-title style="color: #3C4858" class="font-weight-bold">
            Anda Telah Membuka Tab Baru atau meninggalkan halaman tes
          </v-card-title>
          <v-card-text>
            Anda telah membuka tab baru atau meninggalkan halaman tes dan terindikasi melakukan kecurangan, mohon untuk
            tidak membuka tab atau meninggalkan halaman tes lain agar tes tidak dihentikan.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large @click="plus_counter_tab('open_new_tab')" color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- # DIALOG DETECTION SPEECH -->
      <v-dialog v-model="dialog.identification_speech" persistent width="600">
        <v-card flat v-if="user.is_b2b == 0">
          <v-card-title style="color: #3C4858" class="font-weight-bold">
            Percakapan terdeteksi
          </v-card-title>
          <v-card-text>
            Peringatan! Sistem mendeteksi adanya percakapan. Mohon untuk menjaga ketenangan saat tes berlangsung, agar
            tidak mengurangi keakurasian hasil tes.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large @click="plus_counter_tab('speech')" color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card flat v-else>
          <v-card-title style="color: #3C4858" class="font-weight-bold">
            Percakapan terdeteksi
          </v-card-title>
          <v-card-text>
            Peringatan! Sistem mendeteksi adanya percakapan. Mohon untuk menjaga ketenangan saat tes berlangsung, agar
            tidak mengurangi keakurasian hasil tes.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn large @click="plus_counter_tab('speech')" color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- # DIALOG DETECT MULTI FACE -->
      <v-dialog v-model="dialog.identification_face" persistent width="650">
        <v-card flat v-if="user.is_b2b == 0">
          <v-card-title style="color: #3C4858" class="font-weight-bold text-center d-flex justify-center align-center">
            Multi-wajah terdeteksi
          </v-card-title>
          <v-card-text class="text-center">
            <v-img width="315" height="176" contain class="mx-auto mb-5" :src="imagePreview"> </v-img>
            Sistem mendeteksi wajah lebih dari satu, pastikan pengerjaan tes dilakukan sendiri dan jujur.
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn large @click="plus_counter_tab('face_detection')" color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card flat v-else>
          <v-card-title style="color: #3C4858" class="font-weight-bold text-center d-flex justify-center align-center">
            Multi-wajah terdeteksi
          </v-card-title>
          <v-card-text class="text-center">
            <v-img width="315" height="176" contain class="mx-auto mb-5" :src="imagePreview"> </v-img>
            Sistem mendeteksi wajah lebih dari satu, pastikan pengerjaan tes dilakukan sendiri dan jujur.
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn large @click="plus_counter_tab('face_detection')" color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- # DIALOG DETECT NO FACE -->
      <v-dialog v-model="dialog.identification_no_face" persistent width="650">
        <v-card flat v-if="user.is_b2b == 0">
          <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
            Wajah tidak terdeteksi
          </v-card-title>
          <v-card-text class="text-center">
            <v-img width="315" height="176" contain class="mx-auto mb-5" :src="imagePreview"> </v-img>
            Sistem tidak mendeteksi wajah anda, pastikan wajah anda terlihat di kamera selama tes berlangsung
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn large @click="plus_counter_tab('face_detection')" color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card flat v-else>
          <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
            Wajah tidak terdeteksi
          </v-card-title>
          <v-card-text class="text-center">
            <v-img width="315" height="176" contain class="mx-auto mb-5" :src="imagePreview"> </v-img>
            Sistem tidak mendeteksi wajah anda, pastikan wajah anda terlihat di kamera selama tes berlangsung
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn large @click="plus_counter_tab('face_detection')" color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog.end_tes_face" persistent width="600">
        <v-card flat v-if="dialog.end_face_value == 'multi'">
          <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
            Anda terindikasi melakukan kecurangan
          </v-card-title>
          <v-card-text class="text-center">
            <v-img width="315" height="176" contain class="mx-auto mb-5" :src="imagePreview"> </v-img>
            Sistem mendeteksi adanya multi wajah lebih dari 3x, sesuai dengan syarat dan ketentuan yang berlaku maka tes
            anda kami hentikan.
          </v-card-text>
          <!-- <v-card-actions class="d-flex justify-center align-center">
            <v-btn 
              large
              @click="plus_counter_tab('face_detection')" 
              color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions> -->
        </v-card>

        <v-card flat v-if="dialog.end_face_value == 'no_face'">
          <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
            Anda terindikasi melakukan kecurangan
          </v-card-title>
          <v-card-text class="text-center">
            <v-img width="315" height="176" contain class="mx-auto mb-5" :src="imagePreview"> </v-img>
            Sistem tidak mendeteksi wajah anda lebih dari 3x, sesuai dengan syarat dan ketentuan yang berlaku maka tes
            anda kami hentikan.
          </v-card-text>
          <!-- <v-card-actions class="d-flex justify-center align-center">
            <v-btn 
              large
              @click="plus_counter_tab('face_detection')" 
              color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog.end_tes" persistent width="600">
        <v-card flat>
          <v-card-title style="color: #3C4858" class="font-weight-bold">
            Anda terindikasi melakukan kecurangan
          </v-card-title>
          <v-card-text>
            Sistem mendeteksi kecurangan berulang. Peserta dinyatakan gugur sesuai dengan syarat dan ketentuan yang
            berlaku.
          </v-card-text>
          <v-divider></v-divider>
          <!-- <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn 
              large
              :loading="process.run"
              @click="gugur_test()" 
              color="primary">
              Akhiri Tes
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
let SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
let recognition = SpeechRecognition ? new SpeechRecognition() : false;

import EpotQuestion from "../../components/EpotQuestion";
import EpotMixins from "../../mixins/EpotMixins";
import AudioPlayer from "../../components/AudioPlayer";
import html2canvas from "html2canvas";
import * as faceAPI from "face-api.js";
// import EpotCheckMic from '../../components/EpotCheckMic.vue'
// import data_section from "@/store/data_section";

export default {
  name: "epot-section-listening",
  mixins: [EpotMixins],
  components: {
    EpotQuestion,
    AudioPlayer,
    // 'speech-to-text': EpotCheckMic
  },
  data: function() {
    return {
      // SPEECH DETECTION START
      lang: {
        type: String,
        default: "id-ID",
      },
      text: {
        type: [String, null],
        required: true,
      },
      isSection: {
        type: Boolean,
        default: false,
      },
      error: false,
      speaking: false,
      toggle: false,
      runtimeTranscription: "",
      sentences: [],
      array_text: [],
      // SPEECH DETECTION END

      attempt: {
        times: 0,
        error: false,
        alert: false,
      },

      // ==========================
      audios: [],
      interval: {},
      intervalCapture: null,

      dialogSubmit: {},
      imagePreview: "",
      showDetector: false,
      showIconTab: false,
      showIconFace: false,
      showIconSpeech: false,
      board: {
        realTimeCountsFace: 0,
        counter_tab: 0,
        counter_face: 0,
        counter_speech: 0,
      },
      dialog: {
        identification_tab: false,
        identification_face: false,
        identification_no_face: false,
        identification_speech: false,
        end_tes: false,
        end_tes_face: false,
        end_face_value: "",
        face_value_condition: "",
      },
      process: {
        run: false,
      },
      snackbar: {
        state: false,
        timeout: 5000,
        text: "Laptop yang anda gunakan untuk test tidak stabil",
        color: "warning",
        icon: "mdi-information",
      },
      time_limit: 12,
      time_used: 0,
      time_question: 0,
      images: [],
      images_tab: [],
      images_face: [],
      max_time: 0,
      buffering: false,
      submiting: false,
    };
  },
  created() {
    window.history.forward();
    window.onoffline = () => {
      this.onOffline();
    };
    window.ononline = () => {
      console.log("The network connection is connected.");
    };
  },
  async mounted() {
    console.log("starting listening", new Date().toLocaleTimeString());

    this.lms_open_tab();
    this.lms_face_detection();
    this.lms_speech_detection();

    await this.initModel();
    // await this.checkCompatibility()

    // ==================================
    // CAPUTER FACE USER TEST PER 3 MENIT
    let vm = this;
    let randomTimer = Math.floor(Math.random() * (10 - 5 + 1) + 1) * 100000;
    if (vm.user.is_b2b == 1) {
      if (vm.lembaga.active_camera == true) {
        this.intervalCapture = setInterval(() => {
          if (vm.images.length <= 4) {
            this.capture_image();
          } else {
            clearInterval(this.intervalCapture);
          }
        }, randomTimer);
      }
    }

    if (vm.user.is_b2b == 0) {
      this.intervalCapture = setInterval(() => {
        if (vm.images.length <= 4) {
          this.capture_image();
        } else {
          clearInterval(this.intervalCapture);
        }
      }, randomTimer);
    }

    // Identification Open New Tab
    // this.Detection_tab()
    // let $vm = this;

    // console.log($vm.loading.state);

    // if ($vm.loading.state == false) {
    //   document.addEventListener('visibilitychange', function () {
    //     let counter_tab = localStorage.getItem('ytc_lms_counter_open_tab');

    //     if ($vm.user.is_b2b == 1) {
    //       $vm.axios.get(`/institusion/v1/get/institusion/detail/${$vm.user.institusion.id}`).then((response) => {
    //         let res = response.data;
    //         if (res.status == 200) {
    //           $vm.lembaga = res.data
    //         } else{
    //           console.log("hasil2", response);
    //         }
    //       });
    //       if ($vm.lembaga.setting.identification.new_tab == true) {
    //         if (counter_tab == 3) {
    //           $vm.dialog.end_tes = true;
    //           setTimeout(() => {
    //             $vm.gugur_test()
    //           }, 300)
    //         }else{
    //           $vm.dialog.identification_tab = true;
    //         }
    //       }else{
    //         console.log('identification_tab : false');
    //       }
    //     }else{
    //       $vm.dialog.identification_tab = true;
    //     }
    //   })
    // }

    // # IF User B2B
    // if(this.user.institusion.id != ''){
    //   await this.axios.get(`/institusion/v1/get/institusion/detail/${this.user.institusion.id}`).then((response) => {
    //     let res = response.data;
    //     if (res.status == 200) {
    //       $vm.lembaga = res.data
    //       if (this.lembaga.active_camera) {
    //             // console.log('test',this.lembaga.active_camera)
    //             this.$route.meta.prevent_move = true;
    //             navigator.mediaDevices
    //             .getUserMedia({ video: true })
    //             .then((mediaStream) => {
    //               console.log(mediaStream);
    //               this.$refs.video.srcObject = mediaStream;
    //               this.$refs.video.play()
    //             }).catch((error) => {
    //               // this.$refs.video.srcObject = {
    //               //   "active": false,
    //               //   "id": "",
    //               //   "onactive": "null",
    //               //   "onaddtrack": "null",
    //               //   "oninactive": "null",
    //               //   "onremovetrack": "null"
    //               // }
    //               this.$refs.video.pause();
    //               console.error("getUserMedia() error:", error);
    //             });
    //           }else{
    //             // this.$refs.video.srcObject = {
    //             //   "active": false,
    //             //   "id": "",
    //             //   "onactive": "null",
    //             //   "onaddtrack": "null",
    //             //   "oninactive": "null",
    //             //   "onremovetrack": "null"
    //             // }
    //             this.$refs.video.pause();
    //           }
    //     } else{
    //       console.log("hasil2", response);
    //     }
    //   })
    // }

    // if (this.user.institusion.id == '') {
    //   navigator.mediaDevices
    //     .getUserMedia({ video: true })
    //     .then((mediaStream) => {
    //       this.$refs.video.srcObject = mediaStream;
    //       this.$refs.video.play()
    //     })
    //     .catch((error) => {
    //       console.error("getUserMedia() error:", error);
    //     });
    // }

    window.onbeforeunload = function(e) {
      e.preventDefault();
      e.returnValue = "";
    };
    this.$route.meta.prevent_move = true;
  },
  beforeDestroy() {
    clearInterval(this.interval);
    window.onbeforeunload = null;
    window.onoffline = null;
    window.ononline = null;
  },
  watch: {
    // ==================================
    // DIGUNAKAN KETIKA LOADING AWAL BELUM SELESAI TETAPI DETEKSI TAB SUDAH JALAN
    "loading.state": function(val) {
      let vm = this;
      if (val == false) {
        if (vm.user.is_b2b == 1) {
          if (vm.lembaga.setting.identification.new_tab == true) {
            vm.showIconTab = true;
          } else {
            vm.showIconTab = false;
          }
        } else {
          vm.showIconTab = true;
        }
        vm.Detection_tab();
      }
    },

    // ==================================
    // DETECTION CHANGE COUNTER FACE
    "board.realTimeCountsFace": function(face) {
      console.log(face);
      let vm = this;
      if (face < 1) {
        let faceState = localStorage.getItem("ytc_lms_counter_face");
        if (vm.user.is_b2b == 1 && vm.lembaga.setting.failed.face && faceState == 3) {
          if (vm.dialog.face_value_condition == "") {
            vm.capture_image_violation();
            vm.dialog.end_face_value = "no_face";
          }
        } else {
          if (vm.dialog.face_value_condition == "") {
            vm.capture_image_violation();
          }
        }
      }
      if (face > 1) {
        let faceState = localStorage.getItem("ytc_lms_counter_face");
        if (vm.user.is_b2b == 1 && vm.lembaga.setting.failed.face && faceState == 3) {
          if (vm.dialog.face_value_condition == "") {
            vm.capture_image_violation();
            vm.dialog.end_face_value = "multi";
          }
        } else {
          if (vm.dialog.face_value_condition == "") {
            vm.capture_image_violation();
          }
        }
      }

      if (face == 1) {
        vm.snackbar.state = false;
      }
    },
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("ytc_lms_auth"));
    },

    lembaga() {
      return JSON.parse(localStorage.getItem("ytc_lms_institution"));
    },
    // ==================================
    // MODEL FACE DETECTION
    initParams() {
      const data = {
        modelUri: "/models",
        option: new faceAPI.SsdMobilenetv1Options({ minConfidence: 0.5 }),
      };
      return data;
    },

    timer() {
      return this.max_time - this.time_used;
    },

    display_timer() {
      let hours = `${Math.floor(this.timer / 3600)}`.padStart(2, 0);
      let minutes = `${Math.floor(this.timer / 60) % 60}`.padStart(2, 0);
      let seconds = `${this.timer % 60}`.padStart(2, 0);
      return `${hours}:${minutes}:${seconds}`;
    },

    answered_questions() {
      return this.questions.filter((question) => question.answer);
    },

    answered_questions_by_part() {
      return this.parts.map((part) => {
        let question = part.questions
          .map((item) => item.list_question)
          .reduce((val, cur) => [...val, ...cur], [])
          // .filter((item) => item.answer)
          .map((item) => ({
            question_id: item.id,
            answer: item.answer ? [item.answer.text] : [],
          }));

        return {
          part: part.part,
          question,
        };
      });
    },
  },
  methods: {
    backHome() {
      window.onbeforeunload = null;
      this.$route.meta.prevent_move = false;
      this.$router.push("/");
    },
    reset() {
      this.$set(this.attempt, "times", this.attempt.times + 1);
      this.$set(this.attempt, "alert", false);
      this.$set(this.attempt, "error", false);
      this.fetchSection();
    },
    onOnline() {},
    onOffline() {
      console.log("you are offline");
      this.$set(this.attempt, "alert", true);
      this.$set(this.attempt, "error", true);
    },
    // ==================================
    // TAB DETECTION START
    Detection_tab() {
      let $vm = this;
      document.addEventListener("visibilitychange", function() {
        let counter_tab = localStorage.getItem("ytc_lms_counter_open_tab");

        if ($vm.user.is_b2b == 1) {
          // $vm.axios.get(`/institusion/v1/get/institusion/detail/${$vm.user.institusion.id}`).then((response) => {
          //   let res = response.data;
          //   if (res.status == 200) {
          //     $vm.lembaga = res.data
          //   } else{
          //     console.log("hasil2", response);
          //   }
          // });
          if ($vm.lembaga.setting.identification.new_tab == true) {
            $vm.showIconTab = true;
            if (counter_tab == 3 && $vm.lembaga.setting.failed.new_tab == true) {
              $vm.dialog.end_tes = true;
              setTimeout(() => {
                $vm.gugur_test();
              }, 300);
            } else {
              $vm.dialog.identification_tab = true;
            }
          } else {
            $vm.showIconTab = false;
            console.log("identification_tab : false");
          }
        } else {
          $vm.showIconTab = true;
          $vm.dialog.identification_tab = true;
        }
      });
    },
    // TAB DETECTION END
    // ==================================

    // ==================================
    // CAMERA STREAM
    async startStream() {
      this.showDetector = true;
      this.showIconFace = true;
      if (this.user.is_b2b == 1) {
        // await this.axios.get(`/institusion/v1/get/institusion/detail/${this.user.institusion.id}`).then((response) => {
        //   let res = response.data;
        //   if (res.status == 200) {
        // this.lembaga = res.data
        if (this.lembaga.active_camera) {
          this.$route.meta.prevent_move = true;
          navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((mediaStream) => {
              this.$refs.video.srcObject = mediaStream;
              this.$refs.video.play();
              // FACE
              if (this.lembaga.setting.identification.face == true) {
                this.showDetector = true;
                this.showIconFace = true;
                this.runModel();
              } else {
                this.showDetector = false;
                this.showIconFace = false;
                this.board.realTimeCountsFace = 1;
              }
            })
            .catch((error) => {
              this.showDetector = false;
              this.showIconFace = false;
              this.$refs.video.pause();
              console.error("getUserMedia() error:", error);
            });
        } else {
          this.showDetector = false;
          this.showIconFace = false;
          this.$refs.video.pause();
        }
        //   } else{
        //     this.showDetector = false
        //     this.showIconFace = false
        //     console.log("hasil2", response);
        //   }
        // })
      }

      if (this.user.is_b2b == 0) {
        // this.showDetector = true
        // this.showIconFace = true
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((mediaStream) => {
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
            this.showDetector = false;
            this.showIconFace = false;
            // this.runModel();
          })
          .catch((error) => {
            this.showDetector = false;
            this.showIconFace = false;
            console.error("getUserMedia() error:", error);
          });
        window.onbeforeunload = function(e) {
          e.preventDefault();
          e.returnValue = "";
        };
        this.$route.meta.prevent_move = true;
      }
    },
    // CAMERA STREAM END
    // ==================================

    // ==================================
    // FACE DETECTION START
    async initModel() {
      // await faceAPI.nets.ssdMobilenetv1.loadFromUri(this.initParams.modelUri);
      this.startStream();
      // await faceAPI.nets.ageGenderNet.loadFromUri(this.initParams.modelUri)
    },
    async runModel() {
      const result = await faceAPI.detectAllFaces(this.$refs.video, this.initParams.option);

      let canvas = this.$refs.canvas;
      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);

      if (result) {
        const dims = faceAPI.matchDimensions(canvas, this.$refs.video, true);
        const resizeResults = faceAPI.resizeResults(result, dims);
        this.board.realTimeCountsFace = resizeResults.length;
        // faceAPI.draw.drawDetections(canvas, resizeResults)
      }
      setTimeout(() => this.runModel());
    },
    // FACE DETECTION END
    // ==================================

    // ==================================
    // SPEECH DETECTION START
    checkCompatibility() {
      if (!recognition) {
        this.error = "Speech Recognition is not available on this browser. Please use Chrome or Firefox";
      }

      if (this.user.is_b2b == 1) {
        if (this.lembaga.setting.identification.voice) {
          this.showIconSpeech = true;
          this.startSpeechRecognition();
        } else {
          this.showIconSpeech = false;
          console.log("Identification Speech", this.lembaga.identification.voice);
        }
      }

      if (this.user.is_b2b == 0) {
        this.showIconSpeech = true;
        this.startSpeechRecognition();
      }
    },
    endSpeechRecognition() {
      recognition.stop();
      this.toggle = false;
    },
    startSpeechRecognition() {
      if (!recognition) {
        this.error = "Speech Recognition is not available on this browser. Please use Chrome or Firefox";
        return false;
      }
      this.toggle = true;
      recognition.lang = this.lang;
      recognition.interimResults = true;

      recognition.addEventListener("speechstart", (event) => {
        console.log(event);
        this.speaking = true;
      });

      recognition.addEventListener("speechend", (event) => {
        console.log(event);
        this.speaking = false;
        this.array_text = [];
      });

      recognition.addEventListener("result", (event) => {
        Array.from(event.results)
          .map((result) => result[0])
          .map((result) => this.array_text.push(result.transcript));

        let arr = this.array_text[this.array_text.length - 1];
        // console.log('arr',arr);
        // console.log('split_length',arr.split(" ").length);

        if (arr.split(" ").length > 3) {
          // CHECK FOR IS B2B AN GUGUR TEST
          if (this.user.is_b2b == 1) {
            if (this.lembaga.setting.violation.voice) {
              if (this.lms_speech_detection == 3) {
                this.dialog.gugur_test = true;
              } else {
                this.dialog.identification_speech = true;
              }
            }
          }

          // CHECK FROM B2C
          if (this.user.is_b2b == 0) {
            if (arr.split(" ").length > 3) {
              this.dialog.identification_speech = true;
            }
          }
        }
        const text = Array.from(event.results)
          .map((result) => result[0])
          .map((result) => result.transcript)
          .join("");

        this.runtimeTranscription = text;
      });

      recognition.addEventListener("end", () => {
        if (this.runtimeTranscription !== "") {
          this.sentences.push(this.capitalizeFirstLetter(this.runtimeTranscription));
          this.$emit("update:text", `${this.text}${this.sentences.slice(-1)[0]}. `);
        }
        this.runtimeTranscription = "";
        recognition.stop();
        if (this.toggle) {
          // keep it going.
          recognition.start();
        }
      });
      recognition.start();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    // SPEECH DETECTION END
    // ==================================

    // ==================================
    // COUNTER VIOLATION LOCAL_STORAGE
    lms_open_tab() {
      let counter = localStorage.getItem("ytc_lms_counter_open_tab");
      return counter;
    },
    lms_face_detection() {
      let counter = localStorage.getItem("ytc_lms_counter_face");
      return counter;
    },
    lms_speech_detection() {
      let counter = localStorage.getItem("ytc_lms_counter_speech");
      return counter;
    },
    // ==================================

    // ==================================
    // TAMBAH PERHITUNGAN PELANGGARAN
    async plus_counter_tab(type) {
      if (type == "open_new_tab") {
        this.dialog.identification_tab = false;
        let tabCounter = localStorage.getItem("ytc_lms_counter_open_tab");
        this.board.counter_tab += 25;
        this.lms_open_tab();
        let plus = parseInt(tabCounter) + 1;
        localStorage.setItem("ytc_lms_counter_open_tab", plus);
        setTimeout(() => {
          this.capture_image_tab();
        }, 200);
      }

      if (type == "face_detection") {
        this.dialog.identification_face = false;
        this.dialog.identification_no_face = false;
        let faceCounter = localStorage.getItem("ytc_lms_counter_face");
        this.board.counter_face += 25;
        this.lms_face_detection();
        let plus = parseInt(faceCounter) + 1;
        localStorage.setItem("ytc_lms_counter_face", plus);
        this.dialog.face_value_condition = "";
      }

      if (type == "speech") {
        this.dialog.identification_speech = false;
        let license_code = localStorage.getItem("ytc_lms_license");
        let speechCounter = localStorage.getItem("ytc_lms_counter_speech");
        this.board.counter_speech += 25;
        this.lms_speech_detection();
        let plus = parseInt(speechCounter) + 1;
        localStorage.setItem("ytc_lms_counter_speech", plus);
        this.count_violation("voice", license_code, "");
      }
    },
    count_violation(type, license_code, image_id) {
      this.axios
        .put(`/users/v1/member/order/update/violation/${license_code}`, {
          counter_type: type,
          section: "listening",
          image: image_id,
        })
        .then((res) => res.data)
        .then(() => {});
    },

    // ==================================
    // GUGUR TEST
    async gugur_test() {
      this.process.run = true;
      this.submiting = true;
      this.timer_stop();
      clearInterval(this.intervalCapture);
      window.onbeforeunload = null;
      this.$route.meta.prevent_move = false;

      // gugur tes Listening
      await this.axios
        .post(
          "/users/v1/member/order/answer",
          {
            id: this.$route.params.id,
            disqualified: true,
            section: "listening",
            images: this.images,
            answer: [],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          this.submiting = false;
          this.process.run = false;
        })
        .catch((error) => {
          this.submiting = false;
          this.process.run = false;
          this.dialog.end_tes = false;
          this.dialogSubmit = {
            status: "gugur",
            state: true,
            message: error.response.data.message,
          };
        });

      // gugur tes structure
      await this.axios
        .post(
          "/users/v1/member/order/answer",
          {
            id: this.$route.params.id,
            disqualified: true,
            section: "structure",
            images: this.images,
            answer: [],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          this.submiting = false;
          this.process.run = false;
        })
        .catch((error) => {
          this.submiting = false;
          this.process.run = false;
          this.dialog.end_tes = false;
          this.dialogSubmit = {
            status: "gugur",
            state: true,
            message: error.response.data.message,
          };
        });

      // gugur tes reading
      await this.axios
        .post(
          "/users/v1/member/order/answer",
          {
            id: this.$route.params.id,
            disqualified: true,
            section: "reading",
            images: this.images,
            answer: [],
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          this.submiting = false;
          this.process.run = false;
          localStorage.setItem("ytc_lms_counter_open_tab", 0);
          localStorage.setItem("ytc_lms_counter_face", 0);
          localStorage.setItem("ytc_lms_use_face_detection", false);
          this.$router.push({ path: "/epot/end?type=gugur" });
        })
        .catch((error) => {
          this.submiting = false;
          this.process.run = false;
          this.dialog.end_tes = false;
          this.dialog.end_tes_face = false;
          this.dialog.end_face_value = "";
          this.board.counter_tab = 0;
          this.board.counter_face = 0;
          this.board.counter_speech = 0;
          if (error.response.data.message == "Maaf, Tes ini sudah selesai dan terdiskualifikasi") {
            localStorage.setItem("ytc_lms_counter_open_tab", 0);
            localStorage.setItem("ytc_lms_counter_face", 0);
            localStorage.setItem("ytc_lms_use_face_detection", false);
            this.$router.push({ path: "/epot/end?type=gugur" });
          }
          // this.dialogSubmit = {
          //   status: "gugur",
          //   state: true,
          //   message: error.response.data.message,
          // };
        });
    },

    // ==================================
    // CAPUTER PAGE PELANGGARAN TAB
    async capture_image_tab() {
      // let el = this.$refs.screen;
      let el = document.getElementById("app");
      let license_code = localStorage.getItem("ytc_lms_license");

      (await html2canvas(el)).toBlob((blob) => {
        let form_data = new FormData();
        form_data.append("title", `open-tab-listening-${this.images_tab.length + 1}`);
        form_data.append("source", "upload");
        form_data.append("file", blob, `open-tab-${this.images_tab.length + 1}.jpg`);
        this.$store
          .dispatch("media/create", form_data)
          .then((res) => res.data)
          .then((data) => {
            this.count_violation("open_new_tab", license_code, data.id);
            this.images_tab.push(data.id);
          })
          .catch((error) => {
            console.log(error);
            this.count_violation("open_new_tab", license_code, "");
          });
      }, "image/jpeg");
    },

    // ==================================
    async fetchSection() {
      this.loading.state = true;
      this.loading.text = "Please wait";
      this.audios = [];

      await this.axios
        .get(`/users/v1/member/order/detail/${this.$route.params.id}/listening`)
        .then((res) => res.data)
        .then(async (res) => {
          if (res.data.exam.is_complete) {
            window.onbeforeunload = null;
            this.$route.meta.prevent_move = false;
            setTimeout(() => {
              this.$router.push({
                name: "epot-structure",
                params: { id: this.$route.params.id },
              });
              window.location.reload(true);
            }, 1000);
            return;
          }

          this.section = res.data.exam;

          let arr = [];

          // detail exam
          arr = this.parts;
          for (let index = 0; index < arr.length; index++) {
            await this.$store
              .dispatch("exam/detail", arr[index].exam_id)
              .then((res) => res.data)
              .then((res) => {
                this.$set(arr, index, { ...arr[index], ...res });
                let pd = arr[index].part_direction;
                let cs = arr[index].close_statement;

                if (pd.with_media && pd.media.type == "audio")
                  this.audios.push({
                    object: pd.media,
                    url: pd.media.public_url,
                    duration: pd.media.duration
                  });
                if (cs.with_media && cs.media.type == "audio")
                  this.audios.push({
                    object: cs.media,
                    url: cs.media.public_url,
                    duration: cs.media.duration
                  });
              })
              .catch((error) => {
                console.error(error);
              });
          }

          arr = this.lists;
          for (let index = 0; index < arr.length; index++) {
            if (arr[index].with_media && arr[index].media.type == "audio") {
              this.audios.push({
                object: arr[index].media,
                url: arr[index].media.public_url,
                duration: arr[index].media.duration
              });
            }
          }

          // Detail question
          arr = this.questions;
          this.axios
            .get("/bank/v1/question/public_multiple", {
              params: { id: arr.join(",") },
              headers: { "X-Company-ID": this.parts[0].company.id },
            })
            .then((res) => res.data)
            .then((res) => {
              res.data.forEach((val) => {
                this.current = arr.findIndex((v) => v == val.id) + 1;
                const current_list = this.current_list;
                const list_index = current_list.list_question.findIndex((v) => v == val.id);

                this.$set(current_list.list_question, list_index, val);
                let media = this.current_question.question.media;
                if (media && media.type == "audio") this.audios.push({ object: media, url: media.url, duration: media.duration  });
              });
            })
            .finally(() => {
              this.current = 0;
              this.loading.text = `Calculating media duration`;
              this.setMaxTime();
            });
        })
        .catch((error) => {
          console.error(error);
          this.onOffline();
        });
    },

    // ==================================
    // CAPUTER FACE PELANGGARAN FACE
    async capture_image_violation() {
      let license_code = localStorage.getItem("ytc_lms_license");
      let face_detection_skip = localStorage.getItem("ytc_lms_use_face_detection") === "true";
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        let form_data = new FormData();
        form_data.append("title", `listening-violation-${this.images_face.length + 1}`);
        form_data.append("source", "upload");
        form_data.append("file", blob, `listening-violation-${this.images.length + 1}.jpg`);
        this.$store
          .dispatch("media/create", form_data)
          .then((res) => res.data)
          .then((data) => {
            this.imagePreview = data.public_url;

            if (this.dialog.end_face_value == "multi" || this.dialog.end_face_value == "no_face") {
              this.dialog.face_value_condition = "end_test";
              this.dialog.end_tes_face = true;
              setTimeout(() => {
                this.gugur_test();
              }, 300);
            } else {
              if (this.board.realTimeCountsFace == 0 && this.dialog.end_face_value == "") {
                this.dialog.face_value_condition = "no_face";
                if (face_detection_skip) {
                  this.snackbar.state = true;
                  this.snackbar.timeout = -1;
                  this.count_violation("face", license_code, data.id);
                  this.plus_counter_tab("face_detection");
                  this.images_face.push(data.id);
                } else {
                  this.dialog.identification_no_face = true;
                  this.count_violation("face", license_code, data.id);
                  this.images_face.push(data.id);
                }
              } else if (this.board.realTimeCountsFace > 1 && this.dialog.end_face_value == "") {
                this.dialog.face_value_condition = "multi";
                if (face_detection_skip) {
                  this.snackbar.state = true;
                  this.snackbar.timeout = -1;
                  this.count_violation("face", license_code, data.id);
                  this.plus_counter_tab("face_detection");
                  this.images_face.push(data.id);
                } else {
                  this.dialog.identification_face = true;
                  this.count_violation("face", license_code, data.id);
                  this.images_face.push(data.id);
                }
              }
            }
          });
      }, "image/jpeg");
    },

    async capture_image() {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        let form_data = new FormData();
        form_data.append("title", `listening-${this.images.length + 1}`);
        form_data.append("source", "upload");
        form_data.append("file", blob, `listening-${this.images.length + 1}.jpg`);
        this.$store
          .dispatch("media/create", form_data)
          .then((res) => res.data)
          .then((data) => {
            this.images.push(data.id);
          });
      }, "image/jpeg");
    },

    async setMaxTime() {
      const vm = this;

      let loaded = 0;
      for (let index = 0; index < this.audios.length; index++) {
        let audio = new Audio();
        
        // By NUHA count duration media 
        this.audios[index].duration = this.audios[index].duration == 'undefined' || this.audios[index].duration == undefined ? 0 : parseInt(this.audios[index].duration)
        vm.max_time += this.audios[index].duration;
        vm.loading.text = `Calculating media duration (${++loaded}/${vm.audios.length})`;
        vm.$set(vm.audios[index].object, "time_question", this.audios[index].duration);

        if (loaded == vm.audios.length && !vm.attempt.error) {
          setTimeout(() => {
            vm.max_time = Math.ceil(vm.max_time) + 300;
            vm.loading.state = false;
            vm.timer_start();
          }, 2000);
        }

        audio.src = this.audios[index].url;
        // end

        // let audio = new Audio(this.audios[index].url);
        // audio.onloadeddata = function() {
        //   const duration = Math.ceil(this.duration + 1);
        //   vm.max_time += duration;
        //   vm.loading.text = `Calculating media duration (${++loaded}/${vm.audios.length})`;
        //   vm.$set(vm.audios[index].object, "time_question", duration);

        //   if (loaded == vm.audios.length) {
        //     setTimeout(() => {
        //       vm.max_time = Math.ceil(vm.max_time) + 60 * 5;
        //       vm.loading.state = false;
        //       vm.timer_start();
        //     }, 2000);
        //   }
        // };
        // audio.onerror = function(e) {
        //   console.log(e.message);
        //   vm.onOffline();
        // };
      }
    },

    timer_start() {
      this.timer_stop();
      this.interval = setInterval(() => {
        if (this.time_question == 0) {
          this.timer_stop();
          setTimeout(() => {
            this.next();
            this.timer_start();
          }, 500);
        }
        this.time_question--;
        this.time_used++;

        if (this.max_time <= this.time_used) {
          this.go_to_next_section();
        }
      }, 1000);
    },

    timer_stop() {
      clearInterval(this.interval);
    },

    next() {
      // nyari kondisi pas next
      if (this.current == 0) {
        this.show_direction = true;
        this.current++;
      } else if (this.show_direction) {
        this.show_direction = false;
      } else if (this.end_of_part) {
        if (this.show_close_statement && this.end_of_section) {
          this.go_to_next_section();
        } else if (this.show_close_statement) {
          this.show_close_statement = false;
          this.show_direction = true;
          this.current++;
        } else {
          this.show_close_statement = true;
        }
      } else if (this.current_list.is_group && !this.end_of_list) {
        this.current += this.current_list.list_question.length - 1;
        this.next();
        return;
      } else {
        this.current++;
      }

      // Setting timer question
      if (this.show_direction) {
        if (
          this.current_part.part_direction &&
          this.current_part.part_direction.with_media &&
          this.current_part.part_direction.media.type == "audio"
        ) {
          this.time_question = Math.round(
            this.current_part.part_direction.media.time_question
            // 5
          );
        } else {
          this.time_question = 1;
        }
      } else if (this.show_close_statement) {
        if (
          this.current_part.close_statement &&
          this.current_part.close_statement.with_media &&
          this.current_part.close_statement.media.type == "audio"
        ) {
          this.time_question = Math.round(
            this.current_part.close_statement.media.time_question
            // 5
          );
        } else {
          this.time_question = 1;
        }
      } else if (this.current_list.is_group && this.beginning_of_list) {
        if (this.current_list.with_media && this.current_list.media.type == "audio") {
          this.time_question = Math.round(this.current_list.media.time_question);
        }
      } else if (this.current_question && this.current_question.question.media.type == "audio") {
        this.time_question = Math.round(this.current_question.question.media.time_question);
      } else {
        this.time_question = 1;
      }
    },

    button_status(index) {
      if (!this.show_direction && !this.show_close_statement) {
        if (!index || !this.current) {
          return "bg-accent-default";
        }
        if (index == this.current) {
          return "bg-accent-null";
        }
      }
      if (this.questions[index - 1].answer) {
        return "bg-accent-green";
      }
      return "bg-accent-default";
    },

    async go_to_next_section() {
      this.timer_stop();
      clearInterval(this.intervalCapture);
      window.onbeforeunload = null;
      this.$route.meta.prevent_move = false;

      if (this.images.length < 2) {
        if (this.user.is_b2b == 1) {
          if (this.lembaga.active_camera == true) {
            await this.capture_image();
            await this.capture_image();
          }
        }

        if (this.user.is_b2b == 0) {
          await this.capture_image();
          await this.capture_image();
        }
      }

      await this.axios
        .post(
          "/users/v1/member/order/answer",
          {
            id: this.$route.params.id,
            section: "listening",
            images: this.images,
            answer: this.answered_questions_by_part,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data)
        .then(() => {
          this.board.counter_tab = 0;
          this.board.counter_face = 0;
          this.board.counter_speech = 0;
          // this.$router.push({
          //   name: "epot-structure",
          //   params: { id: this.$route.params.id },
          // });
          setTimeout(() => {
            this.$router.push({
              name: "epot-structure",
              params: { id: this.$route.params.id },
            });
            window.location.reload(true);
          }, 1000);
        })
        .catch((error) => {
          this.dialogSubmit = {
            status: "kirim_ulang",
            state: true,
            message: error.response.data.message,
          };
        });
    },

    // go_section() {
    //   window.onbeforeunload = null;
    //   this.$route.meta.prevent_move = false;

    //   this.$router.push({
    //     name: "epot-structure",
    //     params: { id: this.$route.params.id },
    //   });
    // },
  },
};
</script>

<style>
.test-video {
  background-color: #000;
  border-radius: 5px;
  position: fixed;
  right: 1%;
  bottom: 3%;
}
#soal {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.box-volume .v-slider--horizontal .v-slider__track-container {
  height: 5px;
}
.alert-instruction .v-alert--text:before {
  background-color: #fefbd8 !important;
}
.alert-instruction .v-alert--text {
  background: #fefbd8 !important;
}
.theme--light .box-volume .v-text-field > .v-input__control > .v-input__slot:before {
  border-color: transparent;
  border-style: hidden;
  border-width: thin 0 0;
}
.box-volume .v-text-field > .v-input__control > .v-input__slot:after {
  border-style: hidden;
}
@media (min-width: 1200px) {
  .mx-width {
    width: 310px !important;
  }
}
</style>

<template>
  <v-col cols="12" md="12" class="white rounded-lg">
    <v-card elevation="0" class="px-3 py-3 col-md-8 ma-auto" style="">
      <div class="fs-24 color-state font-weight-bold mb-2"><span>Perhatian</span></div>
      <div class=""> <p class="color-disable mb-0">Anda hanya dapat melakukan test 1x untuk setiap pembelian.</p></div>
      <v-card-text class="pa-0 ">
        <v-card-title class="fs-18 font-weight-medium color-state pl-0 pb-2">
          Sebelum memulai test perhatikan  hal-hal berikut :
          <v-spacer></v-spacer>
        </v-card-title>
        <div class="content-guide color-disable">
          <ol class="pl-4 pb-3">
            <!-- <li><span>Siapkan ruang yang aman dan tenang dari gangguan.</span></li> -->
            <li><span>Baca dengan seksama <span class="font-weight-medium color-state"> Syarat & Ketentuan </span> di bawah.</span></li>
            <li><span>Nama yang tertera di sertifikat TOEFL Equivalent Test sesuai dengan nama yang diisikan di profil.</span></li>
            <li><span>Hanya bisa berjalan pada browser <span class="font-weight-medium color-state"> Google Chrome</span>. Update browser anda ke versi terbaru.</span></li>
            <li><span>Gunakan laptop/komputer dengan koneksi internet stabil & tersambung ke sumber listrik.</span></li>
            <li><span>Pastikan mengerjakan tes dalam ruangan dengan pencahayaan yang cukup.</span></li>
            <li><span>Usahakan background ruangan tes sepolos mungkin, tidak boleh memiliki terlalu banyak objek dan tidak boleh ada foto atau poster.</span></li>
            <li><span>Izinkan penggunaan mic, kamera & audio pada halaman Test Page setelah ini.</span></li>
            <li><span>Pastikan berpakaian dengan sopan, karena sistem akan mengambil gambar selama tes berlangsung.</span></li>
            <li><span>Pastikan headset tersambung untuk sesi Listening.</span></li>
            <li><span>Pastikan menjawab soal listening sebelum waktu habis dan berlanjut ke soal berikutnya, karena anda tidak dapat kembali ke soal sebelumnya.</span></li>
            <li><span>Berikut tindakan yang merupakan kecurangan dan akan ditampilkan <span class="font-weight-medium color-state">pada halaman verifikasi saat QR Code discan:</span></span></li>
              <ul class="font-weight-medium color-state">
                <li><span>Dilarang berbicara selama tes berlangsung</span></li>
                <li><span>Dilarang membuka tab lain selama tes berlangsung</span></li>
                <li><span>Dilarang mematikan, menutup, dan pastikan wajah anda menghadap kamera.</span></li>
                <li><span>Dilarang mengerjakan tes dengan bantuan orang lain. </span></li>
                <li><span>Dilarang terdapat lebih dari 1 orang dalam frame selama tes berlangsung</span></li>
              </ul>
              <!-- {{ Object.keys(lembaga).length }} -->
            <li v-if="response.failed.state"><span v-html="response.failed.text"></span></li>
            <li v-if="response.doc.state"><span v-html="response.doc.text"></span></li>
            <li v-if="response.pasal.state"><span v-html="response.pasal.text"></span></li>
            <!-- <li v-if="lembaga.setting.failed.new_tab || lembaga.setting.failed.face || lembaga.setting.failed.voice"><span>Jika melanggar salah satu dari larangan diatas <span class="font-weight-medium color-state">lebih dari 3x</span>, maka tes dihentikan.</span></li> -->
            <!-- <li v-if="lembaga.setting.identification.face || lembaga.setting.failed.voice"><span>Peserta dilarang untuk mendokumentasikan materi program TOEFL Equivalent Test dalam bentuk apapun.</span></li> -->
            <!-- <li v-if="lembaga.setting.identification.face || lembaga.setting.failed.voice"><span>Bagi peserta yang kedapatan melakukan penggandaan/pendokumentasian, akan dilaporkan dengan <span class="font-weight-medium color-state">Pasal 120 Undang-Undang Republik Indonesia Nomor 28 Tahun 2014 Tentang Hak Cipta</span></span></li> -->
            <!-- <li><span>Pastikan tidak menggunakan masker/penutup wajah ketika tes berlangsung.</span></li> -->
            <li><span>Jika ada terjadi kendala website error atau audio listening tidak terdengar, segera hubungi admin TOEFL Equivalent Test melalui WhatsApp <b class="primary--text">0813-2614-9338</b></span></li>
          </ol>
          <p>Jika sudah siap, beri tanda centang pada pernyataan di bawah & klik tombol Mulai Test untuk memulai tes.</p>
          <v-checkbox v-model="checkbox" class="color-check rounded-sm">
            <template v-slot:label>
              <div class="color-state fs-18">
                Saya setuju dengan syarat & ketentuan yang berlaku
              </div>
            </template>
          </v-checkbox>
          <v-btn 
          :disabled="!checkbox"
            color="primary" 
            large
            elevation="0"
            class="mb-2"
            @click="goTest()"
          >
            Mulai Test
          </v-btn>        
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog.tutorial" width="450" persistent>
      <v-card class="rounded-lg">
        <v-card-title class="d-flex justify-center align-center">
          Perhatikan video secara seksama!
        </v-card-title>
        <v-card-text class="text-center px-4 pt-2 pb-1">
          <video
            :autoplay="isPlay"
            controls
            controlsList="nodownload nofullscreen"
            id="video-proctoring"
            style="
              border-radius: 6px;"
            width="100%"
            height="100%"
            :src="require('@/assets/video/Animasi-EPOT-Auto-Online-Proctoring.mp4')">
          </video>
          <v-alert class="fs-12 accent-blue mt-2">
            <div class="d-flex">
              <v-icon class="me-2 color-blue align-start">ri-information-fill</v-icon>
              <p class="color-blue mb-0">
                Anda tidak dapat melanjutkan ke proses selanjutnya sebelum video panduan selesai
              </p>
            </div>
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            :disabled="video_end == true"
            color="primary" 
            large
            width="200"
            elevation="0"
            class="mx-2"
            @click="dialog.tutorial = false">
            Lanjutkan
          </v-btn>  
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>

export default {
  name: "epot-preparation",
  component:{

  },
  data:function(){
    return {
      checkbox:false,
      video_end: false,
      isPlay: false,
      id:'',
      // lembaga: {},
      dialog: {
        tutorial: false,
      },
      response: {
        failed: { 
          state: false,
          text: ""
        },
        doc: { 
          state: false,
          text: ""
        },
        pasal: { 
          state: false,
          text: ""
        }
      },
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem('ytc_lms_auth'));
    },
    lembaga() {
      return JSON.parse(localStorage.getItem('ytc_lms_institution'));
    }
  },
  mounted() {
    localStorage.setItem('ytc_lms_counter_open_tab', 0)
    localStorage.setItem('ytc_lms_counter_face', 0)
    localStorage.setItem('ytc_lms_counter_speech', 0)
    localStorage.setItem("ytc_lms_use_face_detection", false)
    localStorage.setItem("ytc_lms_use_speech_detection", false)

    let vm = this
    document.getElementById('video-proctoring').addEventListener('ended', function() {
      console.log('selesai');
      vm.video_end = false
    })
  },
  created() {
    this.getId()
    // window.location.reload(true);
  },
  methods: {
    goTest() {
      // let id = this.$route.params.id;
      // this.$router.push('/epot/webcam-check', id);
      // this.$router.push({ name: "epot-webcam-check" , params: { id: this.$route.params.id } });
      this.$router.push({ name: "epot-specification" , params: { id: this.$route.params.id } });
    },
    getId() {
      if (this.user.is_b2b == 1) {
        // this.axios.get(`/institusion/v1/get/institusion/detail/${this.user.institusion.id}`).then((response) => {
        //   let res = response.data;
        //   if (res.status == 200) {
        //     this.lembaga = res.data
        //     localStorage.setItem("ytc_lms_institution", JSON.stringify(res.data))
            // if (!this.lembaga.setting.identification.face && !this.lembaga.setting.identification.voice && !this.lembaga.setting.identification.new_tab) {
              this.dialog.tutorial = false
              this.isPlay = false
            // }
            if (this.lembaga.setting.failed.new_tab || this.lembaga.setting.failed.face || this.lembaga.setting.failed.voice) {
              this.response.failed.state = true
              this.response.failed.text = "Jika melanggar salah satu dari larangan diatas <span class='font-weight-medium color-state'>lebih dari 3x</span>, maka tes dihentikan."
            }

            if (this.lembaga.setting.identification.face || this.lembaga.setting.failed.voice) {
              this.response.doc.state = true
              this.response.doc.text = "Peserta dilarang untuk mendokumentasikan materi program EPOT dalam bentuk apapun."
            }

            if (this.lembaga.setting.identification.face || this.lembaga.setting.failed.voice) {
              this.response.pasal.state = true
              this.response.pasal.text = "Bagi peserta yang kedapatan melakukan penggandaan/pendokumentasian, akan dilaporkan dengan <span class='font-weight-medium color-state'>Pasal 120 Undang-Undang Republik Indonesia Nomor 28 Tahun 2014 Tentang Hak Cipta</span>"
            }
        //   } else{
        //     console.log("hasil2", response);
        //   }
        // });
      }

    }
  },
};
</script>

<style>
.content-guide ol{
  padding-left: 15px;
}
.content-guide li {
  /*line-height: 2rem;*/
  margin-bottom: 10px;
}
.content-guide .v-input--selection-controls__input .v-icon {
  color: #8492A6;
}
</style>